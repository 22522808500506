import React, { useState } from "react";
import { useQuery } from "react-query";
import genRequest from "includes/request";
import "./Positions.css"
import PageLoad from "components/PageLoad/PageLoad";
import PositionModel from "./PositionModel";
import Locales from "Locales/Locales";

export default function PositionsController(props){

	const [error, setError] = useState(false);
	const fetchPositions = () => genRequest("positions", null, null, {onlyData: true});
	const fetchMarketsDetails = (epic) => {
		console.log("EPIX", epic)
		if (!epic?.length)
			return (Promise.resolve())
		if (typeof epic !== "string")
			epic = "?epics=" + epic.join(",");
		let prom = genRequest(
			"markets/" + epic,
			null,
			null,
			{onlyData: true});
		return (prom);
	};

	const fetchAll = () => {
		let prom = new Promise((resolve, reject) => {
			let positions;
			fetchPositions().then((pos_resp) => {
				positions = pos_resp.positions;
				let epics = positions.map((pos) => {
					return (pos.market.epic)
				});
				return (fetchMarketsDetails(epics))
			}).then((markets_resp) => {
				if (!markets_resp)
				{
					resolve({
						positions: []
					})
					return (true)
				}
				let markets = markets_resp.marketDetails;
				positions = positions.map((pos) => {
					for (let x in markets)
					{
						if (markets[x].instrument?.epic === pos.market.epic)
						{
							pos = Object.assign(pos, markets[x])
							return (pos)
						}
					}
					return (pos)
				})
				resolve ({
					positions: positions
				})
				return (true)
			}, (e) => {
				reject(e);
			});
		})
		return (prom);
	}

	const {isLoading, data} = useQuery(
		["Positions"],
		fetchAll,
		{
			staleTime: 0,
			onSuccess: (resp) => {
				setError(false);
				return (resp)
			},
			retry: (count, e) => {
				setError(e);
				return (true)
			}
		});

	if (props.status.indexOf("DISCONNECTED") !== -1)
		return ( <PageLoad text="Erreur de connexion"/> );

	if (error)
		return (
			<div className="w-100">
				<div>{Locales("Positions", "LOADING_ERROR")}</div>
				<div className="errors-cont">Erreur {error.response.status?.toString()}: {Locales("API", error.response.data.errorCode)}</div>
			</div>
		);

	if (props.status === "CONNECTING")
		return ( <PageLoad text="Connexion au client LightStream"/>	);

	if (isLoading)
		return ( <PageLoad text="Récupération des positions"/>	);

	let positions = (data.positions ? data.positions : []);


	let genTime = Date.now();
	return (
		<div className="positions-cont">
			{
				positions.map((pos) => {
					return (
						<PositionModel
							takePosition={props.takePosition}
							getConfirm={props.getConfirm}
							genTime={genTime}
							getEpicSub={props.getEpicSub}
							handleSubscribe={props.handleSubscribe}
							key={pos.position.dealId}
							lsClient={props.lsClient}
							forceFetch={props.forceFetch}
							stopLevel={pos.position.stopLevel}
							{...pos}/>
					)
				})
			}
			{ (!positions?.length) && <div className="w-100 text-center">Aucune position a afficher</div>}
		</div>
	)
}
