import { useState } from 'react';

function useToken() {
  const getToken = () => {
    const tokenString = sessionStorage.getItem('token');
	if (!tokenString || tokenString === "undefined")
		return (false)
    const userToken = JSON.parse(tokenString);
    return userToken?.account_token;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    sessionStorage.setItem('token', JSON.stringify(userToken));
    setToken(userToken.account_token);
  };

  return {
    setToken: saveToken,
    token
  }
}

export default useToken;
