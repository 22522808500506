import React, {useState} from "react";
import 'components/Login/Login.css';
import Locales from "Locales/Locales";
import genRequest from "includes/request";
import { pidCryptUtil } from "includes/pidder";
var pidCrypt = require("pidcrypt")
require("pidcrypt/rsa")
require("pidcrypt/asn1")

export default function LoginForm({setToken, setAccount})
{
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [apiKey, setApiKey] = useState("");
	const [error, setError] = useState([]);

	const loginUser = (credentials) => {
		let prom = genRequest(
			"session",
			{
				identifier: credentials.username,
				password: credentials.encryptedPassword,
				encryptedPassword: true
			},
			"post",
			{
				apiKey: credentials.apiKey
			}
		)
		return (prom);
	}
	
	function checkValues(values) {
		let errors = [];
		if (values.username === "" || values.password === "")
			errors.push(Locales("Login", "EMPTY_FORM"));
		if (values.apiKey === "")
			errors.push(Locales("Login", "EMPTY_API_KEY"))
		setError(errors);
		return (errors.length ? false : true)
	}

	function encryptedPassword(key, password) {
		let asn, tree, encrypted;

		try {
			let rsa = new pidCrypt.RSA();
			let decodedKey = pidCryptUtil.decodeBase64(key.encryptionKey);
	
			asn = pidCrypt.ASN1.decode(pidCryptUtil.toByteArray(decodedKey));
			tree = asn.toHexTree();
			rsa.setPublicKeyFromASN(tree);
			encrypted = pidCryptUtil.encodeBase64(pidCryptUtil.convertFromHex(rsa.encrypt(password += '|' + key.timeStamp)));
		} catch (e) {
			encrypted = false
		}
		
		return (encrypted)
	}

	const fetchEncryptionKey = (apiKey) => (genRequest("session/encryptionKey", null, "get", {apiKey: apiKey, apiVersion: 1, onlyData: true}));

	const handleSubmit = async e => {
		e.preventDefault();
	
		let values = {
			username: document.querySelector(".username").value,
			password: document.querySelector(".password").value,
			encryptedPassword: null,
			apiKey: document.querySelector(".api-key").value,
			prodEnv: document.querySelector(".environment-switch").checked
		}
		
		if (!checkValues(values))
			return (false)

		let prom = new Promise((resolve, reject) => {
			let xhr = fetchEncryptionKey(values.apiKey).then((resp) => {
				let encrypted = encryptedPassword(resp, values.password)
				values.encryptedPassword = encrypted;
				return (loginUser(values))
			}, (e) => {
				setError([Locales("API", e.response.data.errorCode)]);
				reject(e)
				throw (e);
			})
			.then((resp) => {
				let account_token = resp.headers["x-security-token"];
				let client_token = resp.headers["cst"];
				let accountId = resp.data.currentAccountId;
				let lsEndpoint = resp.data.lightstreamerEndpoint;
	
				setError([]);
				setToken({
					account_token: account_token,
					client_token: client_token
				});
				setAccount({
					accountId: accountId,
					account_token: account_token,
					client_token: client_token,
					LSClient: null,
					LSEndpoint: lsEndpoint,
					APIKey: values.apiKey,
					Env: (values.prodEnv ? "prod": "demo")
				})
				window.location.assign("/");
				resolve(true);
			},(e) => {
				setError([Locales("API", e.response.data.errorCode)]);
				reject(e)
				return (false);
			})
			return (xhr);
		})
		
		return (prom);
	}

	return (
		<div className="login-main-cont">
			<div className="login-cont">
				<div className="title">IG Watch</div>
				<div className="form-cont">
					<form onSubmit={handleSubmit}>
						<input title="IG client login name" className="username" type="text" name="username" autoComplete="true" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)}/>
						<input title="IG client password" className="password" type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)}/>
						<input title="Application key" type="text" className="api-key" autoComplete="true" name="api-key" placeholder="API key" value={apiKey} onChange={(e) => setApiKey(e.target.value)}/>
						<label className="d-flex align-items-center justify-content-between">
							{ Locales("App", "REAL_ENV") }
							<input className="switch mr-2 environment-switch" type="checkbox"/>
						</label>
						<div className="errors-cont mt-1">{
							error.map((a, i) => {
								return (<div key={i}>{a}</div>)
							})
						}</div>
						<input title="Login using IG client credentials" type="submit" className="btn btn-green login-btn mt-2" value="Login"/>
					</form>
				</div>
			</div>
			<div className="footer d-flex justify-content-between align-items-end">
				<div>
					{ Locales("App", "NOT_AFFILIATED") }
				</div>
				<div className="credit">
					Image credit: <a target="_blank" rel="noopener noreferrer" href="http://energepic.com">energepic.com</a>
				</div>
			</div>
		</div>
	);
}
