import React, { useRef, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import PositionsController from 'components/Positions/PositionsController';
import {Subscription,LightstreamerClient} from 'lightstreamer-client-web/lightstreamer.esm.js';

import HeadBar from 'components/HeadBar/HeadBar';
import TopBar from 'components/TopBar/TopBar';
import useToken from "classes/Accounts/AccountModel/useToken";
import useAccount from "classes/Accounts/AccountModel/useAccount";
import genRequest from "includes/request";
import PageLoad from "components/PageLoad/PageLoad";

export default function Positions() {
	const { token } = useToken();
	const { account } = useAccount();

	const [error, setError] = useState(false);
	const [LSClient, setLSClient] = useState(false);
	const [LSClientStatus, setLSClientStatus] = useState("");
	const [LSEndpoint] = useState(account?.LSEndpoint);
	const [AccountSub, setAccountSub] = useState(false);
	const [Subscriptions, setSubscriptions] = useState([]);
	const [TakePosModal, setTakePosModal] = useState(false);

	const client = useQueryClient();
	let TopBarId = false;

	React.useEffect(() => {
		if (token && LSEndpoint && !LSClient)
			connectToLightStreamer();
	})

	const connectToLightStreamer = () =>
	{
		let is_initied = false;
		if (LSClient)
			return (false);
		var lsClient = new LightstreamerClient(LSEndpoint);
		// Set up login credentials: client
		lsClient.connectionDetails.setUser(account.accountId);

		var password = "";
		if (account.client_token) {
			password = "CST-" + account.client_token;
		}
		if (account.client_token && account.account_token) {
			password = password + "|";
		}
		if (account.account_token) {
			password = password + "XST-" + account.account_token;
		}
		lsClient.connectionDetails.setPassword(password);

		// Add connection event listener callback functions
		lsClient.addListener({
			onListenStart: function () {
				console.log("%c%s", "color:yellow", 'Lightstreamer client - start listening');
			},
			onStatusChange: function (status) {
				let is_connnected = status.match(/CONNECTED:.*-STREAMING/)
				console.log("%s %c%s",'Lightstreamer connection status:', "color: yellow", status);
				if (status !== LSClientStatus && is_connnected && !is_initied)
				{
					is_initied = true;
					setLSClientStatus(status);
				}
			}
		});

		// Allowed bandwidth in kilobits/s
		//lsClient.connectionOptions.setMaxBandwidth();

		// Connect to Lightstreamer
		lsClient.connect();
		setLSClient(lsClient)
	};

	function subscribeToLightstreamerTradeUpdates() {
		// Create a Lightstreamer subscription for the BID and OFFER prices for the relevant market
		// Set up the Lightstreamer FIDs
		let accountSubscription = new Subscription(
			"DISTINCT",
			"TRADE:" + account.accountId,
			[
				"CONFIRMS",
				"OPU",
				"WOU"
			]
		);

		accountSubscription.setRequestedMaxFrequency("unlimited");

		// Set up the Lightstreamer event listeners
		accountSubscription.addListener({
			onSubscription: function () {
				console.log('trade updates subscription succeeded');
			},
			onSubscriptionError: function (code, message) {
				console.error("Error: %s - trade updates subscription failure\nmessage: %s", code, message);
			},
			onItemUpdate: function (updateInfo) {
				console.log("received trade update message: " + updateInfo.getItemName());
				updateInfo.forEachField(function (fieldName, fieldPos, value) {
					if (value !== 'INV') {
						if (fieldName === "CONFIRMS") {
							handleAccountUpdate(value);
						}
					}
				});
			},
			onItemLostUpdates: function (e) {
				console.error("trade updates subscription - item lost", e);
			}
		});

		accountSubscription.type = "ACCOUNT"
		// Subscribe to Lightstreamer
		LSClient.subscribe(accountSubscription);
	}

	// eslint-disable-next-line
	const checkSubs = function(epic) {
		//let subs = LSClient.getSubscriptions();
	}

	const subscribeToItems = function(epicsItems, type, on_update)
	{
		if (LSClient)
		{
			if (epicsItems.length > 0)
			{
				// Set up Lightstreamer FIDs
				var subscription = new Subscription(
					"MERGE",
					epicsItems,
					[
						"BID",
						"OFFER",
						//"MARKET_STATE",
						"CHANGE_PCT"
					]
				);

				subscription.setRequestedSnapshot("yes");

				// Set up Lightstreamer event listener
				subscription.addListener({
					onSubscription: function (resp) {
						console.log("Subscribed to Epics provided", epicsItems, "type: " + type);
					},
					onSubscriptionError: function (code, message) {
						console.log('Subscription failure: ' + code + " message: " + message, epicsItems);
					},
					onItemUpdate: function (updateInfos) {
						var epic = updateInfos.getItemName().split(":")[1];
						updateInfos.forEachField(function(fieldName, fieldPos, value) {
							if (fieldName === "OFFER")
								updatePrice(epic, ".buy-price", value)
							else if (fieldName === "BID")
								updatePrice(epic, ".sell-price", value)
							else if (fieldName === "CHANGE_PCT")
								updatePrice(epic, ".market-variation", value + "%")
						})
						
						if (on_update)
							on_update(updateInfos);
					}
				})

				subscription.type = type;
				// Subscribe to Lightstreamer
				
				LSClient.subscribe(subscription);
				let dup = Subscriptions;
				dup.push(subscription);
				setSubscriptions(dup);
			}
			else
				console.error("Unable to subscribe, no Epics provided", epicsItems, type, on_update);
		} else {
			console.error("Unable to subscribe, no LSClient", epicsItems, type, on_update);
		}
	}

	function getEpicSub(epic) {
		let subs = LSClient.getSubscriptions();

		for (let x in subs) {
			if (subs[x].type === "EPICS")
			{
				let sub = subs[x];
				let params = sub.requestParams?.LS_group;
				if (params.indexOf(epic) !== -1)
					return (sub);
			}
		}
		return (false);
	}

	// eslint-disable-next-line
	const unsubAll = (type) => {
		if (LSClient)
		{
			var subs = LSClient.getSubscriptions();

			for (var x in subs)
				if (!type || (subs[x].type === type))
					LSClient.unsubscribe(subs[x]);
			return (true);
		}
		else
			return (false)
	}

	function updatePrice(epic, classname, value, colors) {
		let elems = document.querySelectorAll("[epic='" + epic + "'] " + classname);

		if (elems.length)
			elems.forEach((a) => {
				let old_price = a.old_price;
				a.innerHTML = value
				a.old_price = value;
				a.setAttribute("value", value)
				if (colors)
				{
					if (!old_price)
						a.classList.add("price-up")
					if (value < old_price)
						a.classList.replace("price-up", "price-down")
					else
						a.classList.replace("price-down", "price-up")
				}
			})
	}

	function handleAccountUpdate(message)
	{
		console.log("UPDATE")
		if (message) {

			var confirm = JSON.parse(message);
			console.log('Account update received: ' + confirm.dealId)
			console.log(confirm);
			// if (confirm.affectedDeals[0]?.status !== "FULLY_CLOSED")
				client.refetchQueries(["Positions"])
		}
	}

	const fetchWatchLists = (listId) => {
		let prom = genRequest(
			"watchlists" + (listId ? ("/" + listId) : ""),
			null, null, {apiVersion: 1, onlyData: true}
		).then((resp) => {
			if (listId)
				return (resp)
			return (resp.watchlists);
		});
		return (prom);
	}

	const fetchTopBar = () => {
		let prom = new Promise((resolve, reject) => {
			let topbar = false;
			if (TopBarId)
			{
				return (
					fetchWatchLists(TopBarId)
						.then((resp) => {

							let topbar = {
								id: TopBarId,
								markets: resp.markets
							};
							resolve (topbar)
							return (topbar)
						}, (e) => {
							reject (e);
							return (Promise.reject(false));
						})
				)
			}
			let req = fetchWatchLists().then((resp) => {
				if (resp && resp.length) {
					for (let x in resp)
					{
						if (resp[x].name === "TopBar")
						{
							topbar = resp[x];
							TopBarId = resp[x].id
							return (fetchWatchLists(resp[x].id))
						}
					}
				}
				let prom = createWatchList("TopBar").then((resp) => {
					topbar = resp;
					TopBarId = resp.id
					topbar.markets = []
					return (topbar);
				})
				return (prom);
			}, (e) => {
				reject (e);
				return (Promise.reject(false));
			}).then((resp) => {
				topbar.markets = resp.markets
				resolve(topbar)
				return (true);
			})
			return (req);
		})
		return (prom);
	}

	const getConfirm = (dealId) => {
		let prom = new Promise((resolve, reject) => {
			genRequest(
				"confirms/" + dealId,
				null,
				null,
				{
					apiVersion: 1,
					onlyData: true
				}
			).then((response) => {
				if (response.dealStatus !== "ACCEPTED")
					reject(response);
				else
					resolve(response);
			})
		});
		return (prom);
	}

	const takePosition = (market_data_or_epic, direction, size) => {
		let data = {
			"epic": "",
			"expiry": "-",
			"direction": "",
			"size": "",
			"orderType": "MARKET",
			"timeInForce": null,
			"level": null,
			"guaranteedStop": false,
			"stopLevel": null,
			"stopDistance": null,
			"trailingStop": false,
			"trailingStopIncrement": null,
			"forceOpen": true,
			"limitLevel": null,
			"limitDistance": null,
			"quoteId": null,
			"currencyCode": "EUR"
		};

		if (typeof market_data_or_epic === "string")
		{
			data.epic = market_data_or_epic;
			data.direction = direction;
			data.size = size;
		} else {
			Object.keys(data).forEach(function(key) {
				if (typeof data[key] !== "undefined" && typeof market_data_or_epic[key] !== "undefined")
					data[key] = market_data_or_epic[key];
			});
			if (direction)
				data.direction = direction;
			if (size)
				data.size = size;
			delete data.instrumentName;
		}
		if (!data.epic)
				return (Promise.reject("Position direction is requiered"));
		if (!data.direction)
				return (Promise.reject("Position direction is requiered"));
		if (!data.size)
			return (Promise.reject("Position size is requiered"));

		let prom = new Promise((resolve, reject) => {
			genRequest(
				"positions/otc",
				JSON.stringify(data),
				"post",
				{ onlyData: true }
			).then((resp) => {
				return (getConfirm(resp.dealReference))
			}).then((confirm_resp) => {
				forceFetch();
				resolve(confirm_resp);
			}, (e) => {
				reject(e);
			})
		});
		return (prom);
	}

	const createWatchList = (name) => {
		let req = genRequest(
			"watchlists",
			{ name },
			"post",
			{
				apiVersion: 1
			}
		);
		return (req)
	}

	const forceFetch = function() {
		client.refetchQueries("Positions");
	}

	let isConnected = (LSClientStatus.indexOf("CONNECTED") !== -1)
	if (isConnected && AccountSub === false && LSClient)
	{
		setAccountSub(true)
		subscribeToLightstreamerTradeUpdates();
	}
	
	const {data} = useQuery(
		["TopBar"],
		fetchTopBar,
		{
			enable: isConnected,
			retry: (count, e) => {
				setError(e);
				return (true)
			},
			onSuccess: (resp) => {
				if (error)
					setError(false);
				return (resp)
			}
		}
	)
	
	if (!isConnected)
		return (
			<PageLoad
				className="text-center"
				text={
					<div>
						Connexion au client Lightstream<br/>
						Cette opération peut prendre jusqu'à une minute
					</div>
				}
			/>
		);
	
	return (
		<div className="wrapper">
			<HeadBar topbar={data} takePosition={takePosition} handleSubscribe={subscribeToItems}/>
			<div className="body-wrapper">
				{
					isConnected ?
					<>
						{ TakePosModal &&
							<TakePositionModal
								market={TakePosModal.market}
								position={TakePosModal.position}
								takePosition= {takePosition}
								close={(() => setTakePosModal(false))}
							/>
						}
						<TopBar
							error={error}
							topbar={data}
							takePosition={takePosition}
							handleSubscribe={subscribeToItems}
							fetchWatchLists={fetchWatchLists}
						/>
						<PositionsController forceFetch={forceFetch} lsClient={LSClient} takePosition={setTakePosModal} getConfirm={getConfirm} getEpicSub={getEpicSub} handleSubscribe={subscribeToItems} status={LSClientStatus}/>
					</>
					: 
					<PageLoad/>
				}				
			</div>
		</div>
	);
}

function TakePositionModal({market, position, takePosition, close}) {

	const [size, setSize] = useState(1);
	const [limit, setLimit] = useState("");
	const [stopLevel, setStopLevel] = useState("");
	const [error, setError] = useState(false);
	const [isUpdating, setIsUpdating] = useState(false)
	const sell_price_ref = useRef();
	const buy_price_ref = useRef();

	function handleTakePosition(dir) {
		let sell_price = parseFloat(sell_price_ref.current.getAttribute("value"));
		let buy_price = parseFloat(buy_price_ref.current.getAttribute("value"));

		if (isUpdating)
			return (false);
		setError(false);
		setIsUpdating(true);
		let market_data = market;
		market_data.direction = dir;
		market_data.size = parseFloat(size);
		if (limit)
			market_data.limitLevel = (dir === "SELL" ? sell_price - parseFloat(limit) : buy_price + parseFloat(limit))
		else
			market_data.limitLevel = null;
		if (stopLevel)
			market_data.stopLevel = (dir === "SELL" ? sell_price + parseFloat(stopLevel) : buy_price - parseFloat(stopLevel))
		else
			market_data.stopLevel = null;
		market_data.currencyCode = position.currency;
		takePosition(market_data).then((resp) => {
			close();
		}, (e) => {
			setError(e.reason)
			setIsUpdating(false)
		})
	}

	return (
		<div className="modal-overlay">
			<div className={"modal-cont" + (isUpdating ? " updating" : "")} epic={market.epic}>
				<div className="updating-cont" title="Updating"></div>
				<div epic={market.epic} className="position-title text-bold text-center mb-3">{market.instrumentName}</div>
				<div className="order-cont d-flex align-items-center justify-content-between mb-2">
					<div className="me-2">Order Size:</div>
					<input className="position-count" min="0" type="number" value={size} onChange={(e) =>{setSize(e.target.value)}}/>
				</div>
				<div className="order-cont d-flex align-items-center justify-content-between mb-2">
					<div className="me-2">Limit Level:</div>
					<input className="position-count" min="0" type="number" value={limit} onChange={(e) =>{setLimit(e.target.value)}}/>
				</div>
				<div className="order-cont d-flex align-items-center justify-content-between mb-2">
					<div className="me-2">Stop Level:</div>
					<input className="position-count" min="0" type="number" value={stopLevel} onChange={(e) =>{setStopLevel(e.target.value)}}/>
				</div>
				<div className="errors-cont my-3 p-2">{error}</div>
				<div className="d-flex flex-column">
					<div className="d-flex mb-2">
						<div className="btn btn-blue me-2" onClick={() => handleTakePosition("SELL")}>
							<div>SELL</div>
							<div className="sell-price" ref={sell_price_ref}>{market.bid}</div>
						</div>
						<div className="btn btn-green" onClick={() => handleTakePosition("BUY")}>
							<div>BUY</div>
							<div className="sell-price" ref={buy_price_ref}>{market.bid}</div>
						</div>
					</div>
					<div className="btn w-100 btn-red" onClick={close}>Close</div>
				</div>
			</div>
		</div>
	)
}
