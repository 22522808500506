export const Locales_fr_FR = {
	Code: "fr-FR",
	CodeMin: "fr",

	App: {
		LOAD_ERROR: "Une erreur est survenue lors du chargement de l'application",
		ERROR_404: "Erreur 404",
		PAGE_NOT_FOUND: "La page demandée n'existe pas",
		DISCONNECT: "Déconnexion",
		REAL_ENV: "Environnement réel",
		NOT_AFFILIATED: "IG Watch n'est en aucun cas affilié à ig.com"
	},

	Commons: {
		WELCOME: "Bienvenue",
		ERROR: "Une erreur est survenue",
		PAGE_LOAD: "Chargement de la page",
		LOADING: "Chargement ...",
		SENDING: "Envoi ...",
		CLOSE: "Fermer",
		CONFIRM: "Confirmer",
		DELETE: "Supprimer",
		SEARCH: "Rechercher",
		ALL_CATEGORIES: "Toutes les catégories",
		FAVORITES: "Favoris",
		ADD_FAVORITE: "Ajouter aux favoris",
		DEL_FAVORITE: "Supprimer des favoris",
		AT: "à",
		LINKS: "Liens"
	},

	Login: {
		EMPTY_FORM: "Veuillez entrer un identifiant et un mot de passe",
		EMPTY_API_KEY: "Veuillez entrer la clé API correspondante à votre compte",
		INCORRECTS_IDS: "L'identifiant ou le mot de passe ne correspond a aucun compte",
		LOGIN_PH: "Identifiant",
		PASS_PH: "Mot de passe",
		SAVE_PASS: "Enregistrer le mot de passe",
		CONNECT: "Se connecter",
		CONNECTING: "Connexion ...",
	},

	Pages: {
		HOME: "Accueil",
	},

	Theme: {
		DARK_THEME: "Thème sombre",
		LIGHT_THEME: "Thème clair",
	},

	Settings: {
		LANGUAGE: "Langue"
	},

	Positions: {
		TITLE: "Titre",
		DIRECTION: "Direction",
		QUANTITY: "Quantité",
		OPEN: "Ouvert",
		CLOSE: "Fermer la position",
		OPEN_LEVEL: "Open level",
		STOP_LEVEL: "Stop level",
		REDUCE: "Réduire",
		BE_AUTO: "BE Auto",
		TP: "TP",
		SELL: "Vente",
		BUY: "Achat",
		LOADING_ERROR: "Erreur de chargement des positions"
	},

	Watchlists: {
		LOADING_ERROR: "Erreur de chargement des favoris"
	},

	API: {
		"error.security.api-key-invalid": "Clé API invalide",
		"error.security.invalid-details": "Identifiants incorrects",
		"error.public-api.exceeded-api-key-allowance": "Limites de l'API atteintes, veuillez patienter 1 minute",
		"STATUS_REJECTED": "Ordre rejeté",
		"REASON": "Raison",
		"REASON_UNKNOWN": "Raison inconnue",
		"REASON_INSTRUMENT_NOT_TRADEABLE_IN_THIS_CURRENCY": "Vous ne pouvez pas trader sur ce marché avec la devise actuelle"
	}
}
