import React, { useState } from "react";

import { useQueryClient } from 'react-query'
// Font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faStar as faSolidStar} from '@fortawesome/free-solid-svg-icons'
import { faStar} from '@fortawesome/free-regular-svg-icons'

import useAccount from "classes/Accounts/AccountModel/useAccount";
import SearchBar from "components/SearchBar/SearchBar";

import "./HeadBar.css"
import SwitchInput from "components/Inputs/Switch/SwitchInput";
import useTheme from "classes/Accounts/AccountModel/useTheme";
import genRequest from "includes/request";
import Locales from "Locales/Locales";
export default function HeadBar(props) {
	const client = useQueryClient();
	const {theme, setTheme} = useTheme();
	const {disconnect} = useAccount();
	const [IsOpen, setIsOpen] = useState(false)
	const [searchRes, setSearchRes] = useState(false)
	const [searchError, setSearchError] = useState(false)

	function changeTheme(e) {
		let checked = e.target.checked;

		if (checked)
			setTheme("dark")
		else
			setTheme("light")
	}

	function search (value) {
		if (!value)
		{
			setSearchRes(false)
			return (Promise.resolve(value));
		}
		genRequest("markets?searchTerm=" + value, "", "get", {onlyData: true, apiVersion: 1}).then((resp) => {
			let markets = resp.markets.splice(0, 10);
			let epics = markets.map((a) => {
				if (a.streamingPricesAvailable)
					return ("L1:" + a.epic)
				return (false)
			}).filter(a => a)
			if (epics.length)
				subscribe(epics);
			setSearchError(false)
			setSearchRes(markets);
		}, (e) => {
			setSearchError(e);
			setSearchRes(false);
		});
	}

	const subscribe = (epics) => {
		console.log("SUB", epics)
		if (props.handleSubscribe)
			props.handleSubscribe(epics, "SEARCH")
	}

	const isInWatchList = (epic) => {
		if (props.topbar?.markets.length)
		{
			for (let x in props.topbar.markets)
			{
				if (props.topbar.markets[x].epic === epic)
					return (true);
			}
		}
		return (false);
	}

	const handleSave = (epic) => {
		let prom;
		if (isInWatchList(epic))
			prom = (rmFromTopBar(epic));
		else
			prom = (addToTopBar(epic));
		prom.then(() => {
			client.refetchQueries("TopBar");
		}, (e) => {
			setSearchError(e);
			return (e)
		})
		return (prom);
	}

	const addToTopBar = (epic) => {
		if (!props.topbar?.id)
			return (Promise.reject())

		let req = genRequest(
			"watchlists/" + props.topbar.id,
			{ epic },
			"put",
			{
				apiVersion: 1
			}
		);
		return (req);
	}
	
	const rmFromTopBar = (epic) => {
		if (!props.topbar?.id)
			return (Promise.reject())

		let req = genRequest(
			"watchlists/" + props.topbar.id + "/" + epic,
			null,
			"post",
			{
				headers: {
					"_method": "DELETE"
				},
				apiVersion: 1
			}
		);
		return (req);
	}

	const genCont = () => {
		if (searchRes && !searchError)
		{
			if (!searchRes.length)
				return (<div>Aucun résultat</div>)
			return (
				<table>
					<tbody>
					{
						searchRes?.map((item, key) => {
							let saved = isInWatchList(item.epic)
							return (<SearchResult key={item.epic} handleSave={() => handleSave(item.epic)} saved={saved} item={item}/>);
						})
					}
					</tbody>
				</table>
			)
		} else if (searchError)
			return (
				<div className="errors-cont">
					{Locales("API", searchError.response.data.errorCode)}
				</div>
			)
		else
			return (false)
	}

	return (
		<div className="head-bar">
			<div className="left-cont">
				<span className="head-title">IG Watch</span>
			</div>
			<SearchBar
				search={search}
				timeout={1000}
				close={() => {
					setSearchError(false)
					setSearchRes(false)
				}}
			/>
			<div className="d-flex right-cont align-items-center">
				<div className="btn-disconnect" onClick={disconnect}>Déconnexion</div>
				<div className="ms-2 cursor-pointer" onClick={() => {setIsOpen(!IsOpen)}}>
					<FontAwesomeIcon className={"head-bar-menu-icon" + (IsOpen ? " open" : "")} icon={faCog}/>
				</div>
			</div>
			<div className={"head-bar-menu" + (IsOpen ? " open" : "")} onMouseLeave={() => {setIsOpen(false)}}>
				<div className="d-flex align-items-center">
					<SwitchInput checked={(theme === "dark" ? true : false)} label={<div className="ms-2">Dark Theme</div>} onChange={changeTheme}/>
				</div>
			</div>
			<div className="search-results-cont">
				<div className="search-results">
					{
						genCont()
					}
				</div>
			</div>
		</div>
	)
}

function SearchResult({item, saved, handleSave}) {

	return (
		<tr epic={item.epic} className="search-result">
			<td className="search-item-title">
				{ item.instrumentName }
			</td>
			<td className="price sell-price">
				{ item.bid }
			</td>
			<td className="price buy-price">
				{ item.offer }
			</td>
			<td className={"fav-icon" + (saved ? " saved" : "")} onClick={handleSave}>
				<FontAwesomeIcon icon={(saved ? faSolidStar : faStar)}/>
			</td>
		</tr>
	)
}

/*
bid: 4405.46
delayTime: 0
epic: "CS.D.ETHUSD.CFD.IP"
expiry: "-"
high: 4426.5
instrumentName: "Ether ($1)"
instrumentType: "CURRENCIES"
low: 4404.2
marketStatus: "TRADEABLE"
netChange: -8.51
offer: 4414.16
percentageChange: -0.19
scalingFactor: 1
streamingPricesAvailable: true
updateTime: "23:14:18"
updateTimeUTC: "22:14:18"
*/
