/* eslint-disable */
export const pidCryptUtil = {

	encodeBase64: function (n, p) {
		if (!n) {
			n = ""
		}
		var g = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
		p = (typeof p == "undefined") ? false : p;
		var f, b, a, r, o, k, j, h, i = [],
			d = "",
			m, q, l;
		q = p ? this.encodeUTF8(n) : n;
		m = q.length % 3;
		if (m > 0) {
			while (m++ < 3) {
				d += "=";
				q += "\0"
			}
		}
		for (m = 0; m < q.length; m += 3) {
			f = q.charCodeAt(m);
			b = q.charCodeAt(m + 1);
			a = q.charCodeAt(m + 2);
			r = f << 16 | b << 8 | a;
			o = r >> 18 & 63;
			k = r >> 12 & 63;
			j = r >> 6 & 63;
			h = r & 63;
			i[m / 3] = g.charAt(o) + g.charAt(k) + g.charAt(j) + g.charAt(h)
		}
		l = i.join("");
		l = l.slice(0, l.length - d.length) + d;
		return l
	},

	decodeBase64: function (n, e) {
		if (!n) {
			n = ""
		}
		var g = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
		e = (typeof e == "undefined") ? false : e;
		var f, b, a, o, k, i, h, q, j = [],
			p, m;
		m = e ? this.decodeUTF8(n) : n;
		for (var l = 0; l < m.length; l += 4) {
			o = g.indexOf(m.charAt(l));
			k = g.indexOf(m.charAt(l + 1));
			i = g.indexOf(m.charAt(l + 2));
			h = g.indexOf(m.charAt(l + 3));
			q = o << 18 | k << 12 | i << 6 | h;
			f = q >>> 16 & 255;
			b = q >>> 8 & 255;
			a = q & 255;
			j[l / 4] = String.fromCharCode(f, b, a);
			if (h == 64) {
				j[l / 4] = String.fromCharCode(f, b)
			}
			if (i == 64) {
				j[l / 4] = String.fromCharCode(f)
			}
		}
		p = j.join("");
		p = e ? this.decodeUTF8(p) : p;
		return p
	},

	encodeUTF8: function (a) {
		if (!a) {
			a = ""
		}
		a = a.replace(/[\u0080-\u07ff]/g, function (d) {
			var b = d.charCodeAt(0);
			return String.fromCharCode(192 | b >> 6, 128 | b & 63)
		});
		a = a.replace(/[\u0800-\uffff]/g, function (d) {
			var b = d.charCodeAt(0);
			return String.fromCharCode(224 | b >> 12, 128 | b >> 6 & 63, 128 | b & 63)
		});
		return a
	},

	decodeUTF8: function (a) {
		if (!a) {
			a = ""
		}
		a = a.replace(/[\u00c0-\u00df][\u0080-\u00bf]/g, function (d) {
			var b = (d.charCodeAt(0) & 31) << 6 | d.charCodeAt(1) & 63;
			return String.fromCharCode(b)
		});
		a = a.replace(/[\u00e0-\u00ef][\u0080-\u00bf][\u0080-\u00bf]/g, function (d) {
			var b = ((d.charCodeAt(0) & 15) << 12) | ((d.charCodeAt(1) & 63) << 6) | (d.charCodeAt(2) & 63);
			return String.fromCharCode(b)
		});
		return a
	},

	convertToHex: function (d) {
		if (!d) {
			d = ""
		}
		var c = "";
		var a = "";
		for (var b = 0; b < d.length; b++) {
			a = d.charCodeAt(b).toString(16);
			c += (a.length == 1) ? "0" + a : a
		}
		return c
	},
	convertFromHex: function (c) {
		if (!c) {
			c = ""
		}
		var b = "";
		for (var a = 0; a < c.length; a += 2) {
			b += String.fromCharCode(parseInt(c.substring(a, a + 2), 16))
		}
		return b
	},
	stripLineFeeds: function (b) {
		if (!b) {
			b = ""
		}
		var a = "";
		a = b.replace(/\n/g, "");
		a = a.replace(/\r/g, "");
		return a
	},
	toByteArray: function (b) {
		if (!b) {
			b = ""
		}
		var c = [];
		for (var a = 0; a < b.length; a++) {
			c[a] = b.charCodeAt(a)
		}
		return c
	},
	fragment: function (e, d, a) {
		if (!e) {
			e = ""
		}
		if (!d || d >= e.length) {
			return e
		}
		if (!a) {
			a = "\n"
		}
		var c = "";
		for (var b = 0; b < e.length; b += d) {
			c += e.substr(b, d) + a
		}
		return c
	},
	formatHex: function (f, e) {
		if (!f) {
			f = ""
		}
		if (!e) {
			e = 45
		}
		var a = "";
		var b = 0;
		var d = f.toLowerCase();
		for (var c = 0; c < d.length; c += 2) {
			a += d.substr(c, 2) + ":"
		}
		d = this.fragment(a, e);
		return d
	},
	byteArray2String: function (a) {
		var d = "";
		for (var c = 0; c < a.length; c++) {
			d += String.fromCharCode(a[c])
		}
		return d
	}

}
/* eslint-enable */
