import Locales from "Locales/Locales";
import React, { Component } from "react";
// import BounceLoader from "react-spinners/BounceLoader";
import "./PageLoad.css"
class PageLoad extends Component {
	
	render() {
		let bgImage = this.props.background ? this.props.background.Url_Origin : null
		return (
			<div className="page-load-cont" >
				<div className={"page-load-bg" + (bgImage ? " hasImage" : "")} style={{backgroundImage: bgImage ? "url(" + bgImage + ")" : false}}>

				</div>
				<div className="inner-load">
					<div className="spinner-cont">
						{/* <BounceLoader color={this.props.color}/> */}
					</div>
					<div>
						<div className="load-msg text-center mt-2" style={{color: this.props.textColor}}>
						{
							this.props.text ? 
								this.props.text
							: Locales("Commons", "PAGE_LOAD")
						}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default PageLoad
