import React, { useState } from "react"

export default function FormInput(props) {

	const [value, setValue] = useState(props.value ? props.value : "")

	function handleChange(e) {
		let prop = e.target.attributes.prop?.value;
		let value = e.target.value;
		if (!props.onChange)
			return (false);
		if (prop)
			props.onChange(prop, value)
		else
			props.onChange(value)
		setValue(value);
	}

	let elem = <input {...props} value={value} placeholder={props.placeholder ? props.placeholder : props.title} onChange={handleChange}/>;
	if (props.textarea && props.textarea === "true")
		elem = <textarea className="w-100" {...props} value={value} placeholder={props.placeholder ? props.placeholder : props.title} onChange={handleChange}></textarea>
	return (
		<div className={"w-100 form-input" + (props.className ? " " + props.className : "")}>
			<div className="mb-1">{props.title}</div>
			<div>{elem}</div>
		</div>
	)
}
