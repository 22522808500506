import genRequest from "includes/request";
import React, { Component } from "react";
import PositionTile from "./Tile/PositionTile";

export default class PositionModel extends Component
{
	constructor(props) {
		super(props);

		const DefaultBE = { state: false, replaced: false }
		const DefaultToggles = [
			{ value: 70, state: false, cap: 20, collected: false },
			{ value: 70, state: false, cap: 10, collected: false },
			{ value: 100, state: false, cap: 100, collected: false }
		];

		this.state = {
			BreakEvent: (props.breakEvent ? props.breakEvent : DefaultBE),
			Toggles: (props.toggles ? props.toggles : DefaultToggles),
			Values: {
				bid: props.market?.bid || 0,
				offer: props.market?.offer || 0,
				mid: (props.market ? (((props.market.offer + props.market.bid) / 2).toFixed(2)) : 0)
			},
			Updating: false,
			LastUpdate: false,
			StopLevel: (props.stopLevel ? props.stopLevel : 0),
			Size: props.position.size
		};
		this.updateBE = this.updateBE.bind(this);
		this.updateTP = this.updateTP.bind(this);
		this.replaceSL = this.replaceSL.bind(this);
		this.setValues = this.setValues.bind(this);
		this.reduce = this.reduce.bind(this);
		this.handleReduce = this.handleReduce.bind(this);
		this.closePosition = this.closePosition.bind(this);

		if (!this.props.getConfirm)
			throw (new Error("getConfirm prop is requiered for PostModel"))
	}

	componentDidMount() {
		const sub = this.props.getEpicSub(this.props.market.epic);

		if (sub) {
			sub.addListener({
				onItemUpdate: this.setValues
			})
		} else if (this.props.handleSubscribe)
			this.props.handleSubscribe(["L1:" + this.props.market.epic], "EPICS", this.setValues);
		this.setState({
			LastUpdate: Date.now()
		})
	}

	setValues = (updateInfos) => {
		if (updateInfos.itemName === "L1:" + this.props.market.epic)
		{
			let values = updateInfos.updateValues;
			let bid = parseFloat(values[2]);
			let offer = parseFloat(values[3]);
			this.setState({
				Values: {
					bid: bid,
					offer: offer,
					mid: ((offer + bid) / 2).toFixed(2)
				}
			}, () => {
				this.checkPosition();
			})
		}
	}

	updateBE = (state) => {
		let dup = this.state.BreakEvent;
		dup.state = state;
		dup.replaced = false;
		this.setState({
			BreakEvent: dup,
			LastUpdate: Date.now()
		}, () => {
			this.checkPosition();
		})
	}

	updateTP = (index, prop, value) => {
		let dup = this.state.Toggles;
		dup[index][prop] = value;
		this.setState({
			Toggles: dup,
			LastUpdate: Date.now()
		}, () => {
			this.checkPosition();
		})
	}

	replaceSL = (new_SL) => {
		let that = this;
		let old_SL = this.state.StopLevel;

		if (this.state.Updating)
			return (false)
		this.setState({	Updating: true });
		if (new_SL === null)
			new_SL = parseFloat(this.props.position.level);
		let req_body = {
			"stopLevel": new_SL
		}
		if (this.state.Updating)
			return (false)
		this.setState({	Updating: true });
		if (!this.state.Updating && new_SL !== old_SL) {
			this.setState({
				StopLevel: new_SL
			})
			let prom = new Promise((resolve, reject) => {
				genRequest(
					"positions/otc/" + this.props.position.dealId,
					JSON.stringify(req_body),
					"put",
					{
						onlyData: true
					}
				).then((resp) => {
					return (that.props.getConfirm(resp.dealReference))
				}).then((confirm_resp) => {
					that.setState({
						StopLevel: new_SL,
						Updating: false,
					}, () => {
						that.props.forceFetch();
					})
					resolve(confirm_resp);
				}, (e) => {
					that.setState({
						StopLevel: old_SL,
						Updating: false,
					}, () => {
						that.props.forceFetch();
					})
					reject(e);
				})
			});
			return (prom)
		}
		return (Promise.all([]));
	};

	handleReduce = (percentage) => {
		if (!percentage)
			return (Promise.all([]))
		return (this.reduce(this.props.position.size * percentage));
	}

	reduce = (value) => {
		let that = this;
		let dealSize = value;

		if (this.state.Updating)
			return (false)
		this.setState({	Updating: true });
		if (!dealSize || isNaN(dealSize))
			dealSize = 0;
		else
			dealSize = Math.round((value + Number.EPSILON) * 100) / 100;
		let data = {
			"dealId": this.props.position.dealId,
			"epic": null,
			"expiry": null,
			"direction": (this.props.position.direction === "BUY" ? "SELL" : "BUY"),
			"size": (dealSize !== undefined ? dealSize.toString() : 0) ,
			"level": null,
			"orderType": "MARKET",
			"timeInForce": null,
			"quoteId": null
		};
	
		let prom = new Promise((resolve, reject) => {
			let req = genRequest(
				"positions/otc",
				data,
				"post",
				{
					onlyData: true,
					apiVersion: 1,
					headers: {
						_method: "DELETE"
					}
				}
			).then((resp) => {
				return (that.props.getConfirm(resp.dealReference))
			}, (e) => {
				this.setState({	Updating: false });
				reject(e);
				return (false);
			}).then((confirm_resp) => {
				this.setState({	Updating: false });
				that.props.forceFetch();
				resolve(confirm_resp);
			}, (e) => {
				this.setState({	Updating: false });
				reject(e);
				return (false);
			});
			return (req);
		})
		return (prom);
	};

	closePosition = () => {
		return (this.reduce(this.props.position.size));
	}

	checkCap = (cap, from_level) => {
		let level = this.props.position.level;

		if (from_level)
			level = from_level;
		if (this.props.position.direction === "BUY") 
		{
			if (this.state.Values.offer >= level + cap)
				return (true);
		} else if (this.props.position.direction === "SELL")
		{
			if (this.state.Values.bid <= level - cap)
				return (true);
		}
		return (false);
	};

	checkSL = () => {
		let cap = this.props.dealingRules.minNormalStopOrLimitDistance.value;
		return (this.checkCap(cap))
	};

	checkToggles = () => {
		let toggles = this.state.Toggles;
		for (let x in toggles)
		{
			let togg = toggles[x];
			if (togg.state && !togg.collected && this.checkCap(togg.cap))
			{
				togg.collected = true;
				let closeNb = Math.round(((this.props.position.size * (togg.value/100)) + Number.EPSILON) * 100) / 100;
				if (closeNb < 0.01)
					closeNb = 0.01;
				this.reduce(closeNb);
			}
		}
		this.setState({
			Toggles: toggles
		});
	};

	checkBE = () => {
		if (this.state.BreakEvent.state === true)
		{
			if (this.state.BreakEvent.replaced === false)
			{
				let limit_rule = this.props.dealingRules.minNormalStopOrLimitDistance.value;
				
				if (this.checkCap(limit_rule, this.state.StopLevel))
				{
					let new_level = null;
					if (this.state.StopLevel)
					{
						let dir = this.props.position.direction;

						if (dir === "BUY")
							new_level = this.state.Values.bid - limit_rule;
						else if (dir === "SELL")
							new_level = this.state.Values.offer + limit_rule;
						if (new_level < this.state.StopLevel)
							return (false);
					}
					this.replaceSL(new_level).then(() => {
						this.setState({
							BreakEvent: {
								replaced: true,
								state: this.state.BreakEvent.state
							}
						})
					});
				}
			}
		}
	};

	checkPosition = () => {
		this.checkBE();
		this.checkSL();
		this.checkToggles();
	};

	render() {
		return (
			<PositionTile
				takePosition={this.props.takePosition}
				dealId={this.props.position.dealId}
				updating={this.state.Updating}
				lastUpdate={this.state.LastUpdate}
				market={ this.props.market }
				position={ this.props.position }
				toggles={this.state.Toggles}
				updateTP={this.updateTP}
				replaceSL={this.replaceSL}
				stopLevel={this.state.StopLevel}
				breakEvent={this.state.BreakEvent}
				updateBE={this.updateBE}
				handleReduce={this.handleReduce}
				handleClose={this.closePosition}
				midPrice={this.state.Values.mid}
			/>
		)
	}
}
