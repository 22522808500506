import axios from 'axios';
import { DEMO_URL, PROD_URL } from './paths';
import { getCurAccount } from 'classes/Accounts/AccountModel/useAccount';
export default function genRequest(url, data, method, props)
{
	let account = getCurAccount();
	let API_KEY = (account?.APIKey ? account?.APIKey : null);
	let ENV = (account?.Env ? account?.Env : null);
	let token = (account?.account_token ? account?.account_token : null)
	let cst = (account?.client_token ? account?.client_token : null)
	let base_url = DEMO_URL;

	if (ENV === "prod")
		base_url = PROD_URL;
	if (props?.apiKey)
		API_KEY = props.apiKey;

	let settings = {
		url: (props && props.forceUrl ? url : base_url + url),
		headers: {
			"X-IG-API-KEY": API_KEY,
			"Content-Type": "application/json; charset=UTF-8",
			"Accept": "application/json; charset=UTF-8",
			"Version": (props?.apiVersion ? props.apiVersion : 2),
			...(props?.headers)
		},
		data: data,
		method: (method ? method : "get"),
		onUploadProgress: props?.onProgress,
		onDownloadProgress: props?.onProgress
	};

	if (token)
		settings.headers["X-SECURITY-TOKEN"] = token
	if (cst)
		settings.headers["CST"] = cst

	if (!method && data)
		settings.method = "post";

	let req = axios(settings).then((resp) => {
		if (props?.onlyData)
			return (resp.data);
		return (resp);
	})

	return (req)
}
