import { useState } from 'react';

function useTheme(over_theme) {
  const getTheme = () => {
    const themeString = localStorage.getItem('theme');
	document.querySelector("body").classList.add("theme-" + themeString)
    return themeString;
  };

  const [theme, setTheme] = useState(getTheme());

  const saveTheme = userTheme => {
	document.querySelector("body").classList.remove("theme-" + theme)
    localStorage.setItem('theme', userTheme);
	document.querySelector("body").classList.add("theme-" + userTheme)
	return (setTheme(userTheme));
  };

  return {
    setTheme: saveTheme,
    theme
  }
}

export default useTheme;
