export const Locales_en_EN = {
	Code: "en-EN",
	CodeMin: "en",

	App: {
		LOAD_ERROR: "An error occurred while loading the App",
		ERROR_404: "Error 404",
		PAGE_NOT_FOUND: "The page you are looking for does not exist",
		DISCONNECT: "Disconnect",
		REAL_ENV: "Real environment",
		NOT_AFFILIATED: "IG Watch is in no way affiliated with ig.com"
	},

	Commons: {
		WELCOME: "Welcome",
		ERROR: "An error occurred",
		PAGE_LOAD: "Page is loading",
		LOADING: "Loading...",
		SENDING: "Sending ...",
		CLOSE: "Close",
		CONFIRM: "Confirm",
		DELETE: "Delete",
		SEARCH: "Search",
		ALL_CATEGORIES: "All categories",
		FAVORITES: "Favorites",
		ADD_FAVORITE: "Add to favorites",
		DEL_FAVORITE: "Remove from favorites",
		AT: "at",
		LINKS: "Links"
	},

	Login: {
		EMPTY_FORM: "Veuillez entrer un identifiant et un mot de passe",
		EMPTY_API_KEY: "Veuillez entrer la clé API correspondante à votre compte",
		INCORRECTS_IDS: "The username or password does not correspond to any account",
		LOGIN_PH: "Username",
		PASS_PH: "Password",
		SAVE_PASS: "Save password",
		CONNECT: "Log in",
		CONNECTING: "Login in ...",
	},

	Pages: {
		HOME: "Home",
	},

	Theme: {
		DARK_THEME: "Dark theme",
		LIGHT_THEME: "Light theme",
	},

	Settings: {
		LANGUAGE: "Language"
	},

	Positions: {
		TITLE: "Titre",
		DIRECTION: "Direction",
		QUANTITY: "Quantité",
		OPEN: "Open",
		CLOSE: "Close position",
		OPEN_LEVEL: "Open level",
		STOP_LEVEL: "Stop level",
		REDUCE: "Reduce",
		BE_AUTO: "BE Auto",
		TP: "TP",
		SELL: "Sell",
		BUY: "Buy",
		LOADING_ERROR: "An error occured while retrieving positions"
	},

	Watchlists: {
		LOADING_ERROR: "Watchlists loading error"
	},

	API: {
		"error.security.api-key-invalid": "Invalid API Key",
		"error.security.invalid-details": "Invalid username or password",
		"error.public-api.exceeded-api-key-allowance": "API limit reached, please wait 1 minute",
		"REASON": "Reason",
		"STATUS_REJECTED": "Rejected order",
		"REASON_UNKNOWN": "Unknown reason",
		"REASON_INSTRUMENT_NOT_TRADEABLE_IN_THIS_CURRENCY": "Instrument not tradeable in thiscurrency"
	}
}
