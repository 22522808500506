import React, { Component, useRef, useState } from "react";
// Font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes} from '@fortawesome/free-solid-svg-icons'

import Locales from "Locales/Locales";

import "./TopBar.css"

export default class TopBar extends Component {

	constructor (props) {
		super (props);

		this.cont_ref = React.createRef();
		this.handleArrows = this.handleArrows.bind(this);
		this.handleScroll = this.handleScroll.bind(this);
		this.genCont = this.genCont.bind(this);
	}

	componentDidMount() {
		if (this.props.topbar?.markets?.length)
		{
			let epics = this.props.topbar?.markets.map((a) => {
				if (a.streamingPricesAvailable)
					return ("L1:" + a.epic);
				return (false);
			}).filter(a => a)
			if (this.props.handleSubscribe)
				this.props.handleSubscribe(epics, "EPICS")
		}
	}

	handleArrows(dir) {
		let cont = this.cont_ref.current;
		let cur_scroll = cont.scrollLeft;
		let tile = cont.querySelector(".top-bar-item")
		let tile_size = tile?.offsetWidth;
		let tile_margin = parseFloat(tile.computedStyleMap().get("margin-right").toString());
		if (dir < 0)
			cont.scrollLeft = cur_scroll - tile_size - tile_margin;
		else
			cont.scrollLeft = cur_scroll + tile_size + tile_margin;
	}

	handleScroll(e) {
		let cont = this.cont_ref.current;
		cont.scrollLeft += e.nativeEvent.deltaY
	}
	
	genCont() {
		if (this.props.error)
		{
			return (
				<div className="w-100">
					<div>{Locales("Watchlists", "LOADING_ERROR")}</div>
					<div className="errors-cont">Erreur {this.props.error?.response.status?.toString()}: {Locales("API", this.props.error?.response.data.errorCode)}</div>
				</div>
			)
		}

		if (this.props.topbar?.markets?.length)
		{
			let markets = this.props.topbar?.markets.sort((a, b) => (a.instrumentName < b.instrumentName ? -1 : 1)).map((market, b) => {
				return (<MarketItem key={market.epic} market={market} takePosition={this.props.takePosition}/>)
			})
			return (markets)
		}
		
		return (
			<div className="d-flex w-100 no-market align-items-center justify-content-center">
				Ajoutez des marchés en favoris depuis la barre de recherche
			</div>
		)
	}

	
	render () {
		return (
			<div className="top-bar d-flex">
				<div className="markets-list" ref={this.cont_ref} onWheelCapture={this.handleScroll.bind(this)}>
					{ this.genCont() }
				</div>
				<div className="d-flex flex-column arrows align-items-center justify-content-center ml-1">
					<div className="col d-flex p-0 align-items-center justify-content-center px-1 prev" dir="prev" onClick={() => this.handleArrows(-1)}>
						<div></div>
					</div>
					<hr/>
					<div className="col d-flex p-0 align-items-center justify-content-center px-1 next" dir="next" onClick={() => this.handleArrows(1)}>
						<div></div>
					</div>
				</div>
			</div>
		)
	}
}

function MarketItem({market, takePosition}) {


	let title = "Title:\t" + market.instrumentName +
				"\nEpic:\t" + market.epic;

	const [isUpdating, setIsUpdating] = useState(false);
	const [size, setSize] = useState(1);
	const [limit, setLimit] = useState("");
	const [stopLevel, setStopLevel] = useState("");
	const [error, setError] = useState(false);

	const sell_price_ref = useRef();
	const buy_price_ref = useRef();
	function handleTakePosition(dir) {
		let sell_price = parseFloat(sell_price_ref.current.getAttribute("value"));
		let buy_price = parseFloat(buy_price_ref.current.getAttribute("value"));

		if (isUpdating)
			return (false);
		setError(false);
		setIsUpdating(true);
		let market_data = market;
		market_data.direction = dir;
		market_data.size = parseFloat(size);
		if (limit)
			market_data.limitLevel = (dir === "SELL" ? sell_price - parseFloat(limit) : buy_price + parseFloat(limit))
		else
			market_data.limitLevel = null;
		if (stopLevel)
			market_data.stopLevel = (dir === "SELL" ? sell_price + parseFloat(stopLevel) : buy_price - parseFloat(stopLevel))
		else
			market_data.stopLevel = null;
		takePosition(market_data).then((resp) => {
			setIsUpdating(false)
		}, (e) => {
			setError(
				<div className="text-start w-100">
					{Locales("API", "STATUS_" + e.dealStatus)}<br/>
					<u><i>{Locales("API", "REASON")}</i></u>:<br/>
					{Locales("API", "REASON_" + e.reason)}
				</div>
			);
			setIsUpdating(false);
		})
	}

	return (
		<div className={"top-bar-item" + (isUpdating ? " updating" : "")} epic={market.epic}>
			<div className="updating-cont"></div>
			<div className="d-flex justify-content-between align-items-center text-bold mb-1" title={title}>
				<div className='text-bold instrument-name'>{ market.instrumentName }</div>
				<div className={"market-variation text-bold " + (market.percentageChange >= 0 ? "color-green" : "color-red")}>{ market.percentageChange }%</div>
			</div>
			<div className='prices d-flex'>
				<div className='col text-center sell-btn p-1' dir="SELL" onClick={(e) => {handleTakePosition("SELL")}}>
					<div className='text-uppercase'>{Locales("Positions", "SELL")}</div>
					<div className='text-bold sell-price' ref={sell_price_ref} value={market.bid}>{market.bid}</div>
				</div>
				<div className="h-sep"></div>
				<div className='col text-center buy-btn p-1' dir="BUY" onClick={(e) => {handleTakePosition("BUY")}}>
					<div className='text-uppercase'>{Locales("Positions", "BUY")}</div>
					<div className='text-bold buy-price' ref={buy_price_ref} value={market.offer}>{market.offer}</div>
				</div>
			</div>
			<div className='d-flex mt-2 take-values'>
				<div>
					<label className='d-flex mr-2 align-items-center'>
						Size: <input type='number' value={size} field='size' min="1" className='size-value ml-1' onChange={(e) => {setSize(e.target.value)}}/>
					</label>
				</div>
				<div>
					<label className='d-flex mr-2 align-items-center'>
						L:<input type='number' value={limit} field='limit' min="0" className='limit-value ml-1' onChange={(e) => {setLimit(e.target.value)}}/>
					</label>
				</div>
				<div>
					<label className='d-flex mr-2 align-items-center'>
						S:<input type='number' value={stopLevel} field='stop' min="0" className='stop-value ml-1' onChange={(e) => {setStopLevel(e.target.value)}}/>
					</label>
				</div>
			</div>
			<div className="errors-cont text-center mt-2 closable">
				{ error && 
					<div>
						<div className="close-errors-btn cursor-pointer" onClick={() => setError(false)}>
							<FontAwesomeIcon icon={faTimes}/>
						</div>
						<div>{error}</div>
					</div>
				}
			</div>
		</div>
	)
}
