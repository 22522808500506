import React from 'react';

import { Locales_en_EN } from "./Strings/en-EN.jsx"
import { Locales_fr_FR } from "./Strings/fr-FR.jsx"
import "./Locales.css"

const DEFAULT_CODE = "en-EN";
const Items = [
	{
		minCode: "fr",
		code: "fr-FR",
		title: "Français",
		locales: Locales_fr_FR,
	},
	{
		minCode: "en",
		code: "en-EN",
		title: "English",
		flag_code: "US",
		locales: Locales_en_EN,
	}
];

export function LocalesWrapper(props) {

	let code = (props.code ? props.code : DEFAULT_CODE);

	localStorage.setItem("lang-code", code);

	return (
		<div code={code}>
			{props.children}
		</div>
	)
}

export function LocalesMenu(props) {

	function handleClick(code) {
		if (props.handleChange)
			props.handleChange(code)
	}

	let flags_url = "https://purecatamphetamine.github.io/country-flag-icons/3x2/"

	return (
		<div className="locales-menu-cont">
			{
				Items.map((lang) => {
					let flag_code;
					if (lang.flag_code)
						flag_code = lang.flag_code;
					else
						flag_code = lang.code.split("-")[1];
					return(
						<div className="locales-item" key={lang.code}>
							<a href={"#" + lang.code}  onClick={() => handleClick(lang.code)}>
								<div className={"flag-icon me-2"}>
									<img alt={"flag-icon-" + lang.code} src={flags_url + flag_code + ".svg"}/>
								</div>
								<div>
									{lang.title}
								</div>
							</a>
						</div>
					)
				})
			}
		</div>
	)
}

function getSting(group, id, code) {
	const LocalesStrings = getStrings(code)

	if (group && LocalesStrings[group])
	{
		if (id && LocalesStrings[group][id])
			return (LocalesStrings[group][id])
		else if (typeof LocalesStrings[group] !== "object")
			return (LocalesStrings[group])
	}
	return ("{ " + code + "." + group + "." + id + " }");
}

export default function Locales(group, id, code) {

	if (!code)
	{
		code = getSessCode();
		if (!code)
			code = DEFAULT_CODE;
	}

	function getSessCode() {
		let sess_code = localStorage.getItem("lang-code");
		if (sess_code)
			return (sess_code)
		return (false);
	}

	let string = getSting(group, id, code)
	if (typeof group === "object" && group.length)
	{
		string = group.map((item) => {
			let ret = getSting(
				item[0],
				item[1],
				item[3]
			)
			return (ret);
		}).join(" ");
		return (string)
	}
	
	return (string)
}

function getStrings(code)
{
	if (!code)
		return (Locales_en_EN);

	for (let x in Items) {
		if (Items[x].code === code || Items[x].minCode === code)
			return (Items[x].locales);
	}
	return (false);
}
