import React from "react";

import "./PositionTile.css"
import Locales from "Locales/Locales";
import SwitchInput from "components/Inputs/Switch/SwitchInput";
import FormInput from "components/Inputs/FormInput";

export default function PositionTile({
	market,
	position,
	toggles,
	breakEvent,
	midPrice,
	updateTP,
	updateBE,
	replaceSL,
	stopLevel,
	handleReduce,
	handleClose,
	updating,
	takePosition
})
{
	let sl_timeout = null;
	let title = "Title: \t" + market.instrumentName +
				"\nEpic: \t" + market.epic +
				"\nDealId:\t" + position.dealId;
	if (!toggles)
		toggles = [];

	function handleUpdateSL(value) {
		if (updating || !replaceSL)
			return (false);
		clearTimeout(sl_timeout)
		sl_timeout = setTimeout(() => {
			if (replaceSL && !updating)
			{
				replaceSL(parseFloat(value))
			}
		}, 1000);
	}

	function reduce(value) {
		if (updating || !handleReduce)
			return (false)
		handleReduce(value)
	}

	function close() {
		if (updating || !handleClose)
			return (false)
		handleClose()
	}

	return (
		<div id={position.dealId} epic={market.epic} className={"position-tile" + (updating ? " updating" : "")}>
			<div className="pos-updating-cont" title="Updating position"></div>
			<div className="pos-head-cont d-flex flex-column" title={title}>
				<div className="d-flex justify-content-between align-items-center text-bold w-100 mt-1" title={title} onClick={() => takePosition({market, position})}>
					<div className='me-2 instrument-name'>{ market.instrumentName }</div>
					<div className="market-mid">{(updating || !midPrice ? "..." : midPrice) + " " + position.currency}</div>
				</div>
				<div className="d-flex text-bold w-100 justify-content-end">
					<div className={"market-variation " + (market.percentageChange >= 0 ? "color-green" : "color-red")}>{ market.percentageChange }%</div>
				</div>
			</div>
			<div className="pos-body">
				<div>
					<div title={Locales("Positions", "DIRECTION")}>{position.direction}</div>
					<div title={Locales("Positions", "QUANTITY")}>{position.size}</div>
					<div className="d-flex align-items-center">
						<div title={Locales("Positions", "OPEN_LEVEL")} className="me-1">{Locales("Positions", "OPEN")}</div>
						<div className="pos-open-level me-1">{position.level}</div>
						<div className="pos-currency">{position.currency}</div>
					</div>
				</div>
				<div>
					<div>
						{Locales("Positions", "STOP_LEVEL")}
					</div>
					<div>
						<input className="sl-input" type="number" value={stopLevel ? stopLevel : 0} onChange={handleUpdateSL}/>
					</div>
				</div>
				<div>
					<div>
						{Locales("Positions", "REDUCE")}
					</div>
					<div className="d-flex w-75 reducer-cont">
						<Reducer handleReduce={reduce} value={0.1}/>
						<Reducer handleReduce={reduce} value={0.25}/>
						<Reducer handleReduce={reduce} value={0.5}/>
						<button className="reduce-btn" onClick={close}>Fermer</button>
					</div>
				</div>
				<BreakEvent {...breakEvent} position={position} updateBE={updateBE}/>
				{
					toggles.map((tog, key) => {
						return (<TakeProfit updateTP={updateTP} key={key} {...tog} index={key}/>)
					})
				}			
			</div>
		</div>
	)
}

function Reducer({value, handleReduce}) {
	return (
		<button className="reduce-btn" onClick={() => handleReduce(value)} value={value}>{value * 100}%</button>
	)
}

function BreakEvent({state, replaced, updateBE}) {
	return (
		<div>
			<div>
				{ Locales("Positions", "BE_AUTO")}
			</div>
			<div>
				<SwitchInput
					switchClassName={state && !replaced ? "load" : ""}
					checked={state}
					onChange={e => {
						updateBE(e.target.checked)
					}}
				/>
			</div>
		</div>
	)
}

function TakeProfit({index, value, state, cap, collected, updateTP})
{
	return (
		<div>
			<div>
				{ Locales("Positions", "TP")}{index + 1}
			</div>
			<div className="d-flex align-items-center">
				<FormInput
					type="number"
					value={value}
					step="1"
					min={0}
					max={100}
					onChange={value => { updateTP(index, "value", value)}}
				/>
				<span className="ms-1">%</span>
			</div>
			<div>
				{ Locales("Commons", "AT")}
			</div>
			<div className="d-flex align-items-center">
				<FormInput 
					type="number"
					value={cap}
					step="1"
					min="0"
					onChange={value => { updateTP(index, "cap", value) }}
				/>
			</div>
			<div>
				<SwitchInput
					checked={state}
					onChange={e => { updateTP(index, "state", e.target.checked)	}}
					switchClassName={(state && !collected ? "wait" : "")}
				/>
			</div>
		</div>
	);
}
