import { useState } from 'react';

export default function useAccount() {

 	const [account, setAccount] = useState(getCurAccount());

	const setCurAccount = (userAccount, no_refresh) => {
		sessionStorage.setItem('account', JSON.stringify(userAccount));
		setAccount(userAccount);
	};

	const disconnect = () => {
		sessionStorage.clear();
		window.location.replace("/");
	}

	return {
		setAccount: setCurAccount,
		disconnect,
		account: account
	}
}

export function getCurAccount() {
	const accountString = sessionStorage.getItem('account');
    const userAccount = JSON.parse(accountString);
    return userAccount;
}
