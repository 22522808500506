import React from "react";

import "./SwitchInput.css"

export default function SwitchInput(props) {
	return (
		<label className={"d-flex align-items-center cursor-pointer" + (props.className ? " " + props.className : "")}>
			<input type="checkbox" className={"switch" + (props.switchClassName ? " " + props.switchClassName : "")} checked={props.checked} disabled={props.disabled} onChange={props.onChange}/>
			<div>
				{ props.label }
			</div>
		</label>
	)
}
