import React, { useRef, useState } from "react"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes} from '@fortawesome/free-solid-svg-icons'
import Locales from "Locales/Locales";
import "./SearchBar.css"

export default function SearchBar({search, timeout, close}) {

	let input_ref = useRef();
	const [isOpen, setIsOpen] = useState(false)
	const [isSearching, setIsSearching] = useState(false)
	const [prevTM, setPrevTM] = useState(false)
	const [term, setTerm] = useState("")
	if (!search)
		throw (new Error("search prop is requiered for SearchBar Component"));
	function handleSearch(val) {
		if (!isOpen)
		{
			setIsOpen(true);
			return (true);
		}

		if (typeof val !== "string")
			val = input_ref.current.value;

		clearTimeout(prevTM)
		setIsSearching(true);
		setTerm(val)
		let prev_timeout = setTimeout(() => {
			let prom = search(val);

			if (prom && prom.then) {
				prom.then((resp) => {
					setIsSearching(false);
				}, () => {
					search("");
					setIsSearching(false)
				})
			} else
				setIsSearching(false);

		}, timeout ? timeout : 0);
		setPrevTM(prev_timeout);
	}

	function handleUnfocus(e) {
		if (!term)
		{
			setIsOpen(false);
			setIsSearching(false);
			clearTimeout(prevTM);
			if (close)
				close();
		}
	}

	function handleClear() {
		setTerm("");
		if (close)
			close();
	}
	return (
		<div className={"search-cont" + (isOpen ? " open" : "") + (isSearching ? " load" : "")} title={Locales("Commons", "SEARCH")}>
			<input ref={input_ref} type="text" placeholder={Locales("Commons", "SEARCH")} onBlur={handleUnfocus} value={term} onChange={(e) => handleSearch(e.target.value)}/>
			{
				term && 
				<div className="clear-search-bar" onClick={handleClear}>
						<FontAwesomeIcon icon={faTimes}/>
				</div>
			}
			<button className="search-btn" type="submit" onClick={handleSearch}></button>
		</div>
	)
}
