import React, { useState } from 'react';
import './App.css';
import './fonts.css';
import useToken from 'classes/Accounts/AccountModel/useToken';
import useAccount from 'classes/Accounts/AccountModel/useAccount';
import useTheme from 'classes/Accounts/AccountModel/useTheme';
import { LocalesWrapper } from 'Locales/Locales';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import LoginForm from 'components/Login/LoginForm';
import Positions from 'components/Positions/Positions';

export default function App() {
	const { token, setToken } = useToken();
	const { setAccount } = useAccount();
	const { theme } = useTheme();
	const [langCode, setLang] = useState(localStorage.getItem("lang-code") || navigator.language || navigator.userLanguage || "en-EN");
	
	document.querySelector("body").classList.add("theme-" + theme)

	function changeLang(code) {
		localStorage.setItem("lang-code", code);
		window.location.reload()
	}

	if (!token) {

		return (
			<LocalesWrapper code={langCode}>
				<Router>
					<Switch>
						<Route path="*">
							<LoginForm  setToken={setToken} setLangCode={setLang} setAccount={setAccount} />
						</Route>
					</Switch>
				</Router>
			</LocalesWrapper>
		);
	}

	return (
		<LocalesWrapper code={langCode}>
			<Positions changeLang={changeLang}/>
		</LocalesWrapper>
		)
}
